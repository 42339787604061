<template>
  <div
    class="MainBackButton --global-clickable"
    @click="$emit('request-exit')">
    <svgicon icon="arrow-left" />
    <span class="label">{{ $t('back') }}</span>
  </div>
</template>

<translations>
  back: Back
  back_no: 'Tilbake'
</translations>

<script>
export default {};
</script>

<style lang="scss" scoped>
.MainBackButton {
    pointer-events: all;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0.5em 0.5em;
    // margin-left: 0.5em;
    color: black;
    border-radius: 0.5em;
    // align-self: center;
    box-shadow: 0 0 1em rgba(black, 0.3);

    .svg-icon {
        $size: 1em;
        width: $size;
        height: $size;
        fill: rgba(black, 0.8);
    }
}

@include media('<mobile') {
    .MainBackButton {
        position: relative;
        &::after {
            // Larger click-surface
            $offset: -0.5em;
            display: block;
            content: '';
            position: absolute;
            top: $offset;
            right: $offset;
            bottom: $offset;
            left: $offset;
        }
    }
    .label {
        display: none;
    }
}
</style>
